<template>
  <b-navbar
    style="height: 60px"
    toggleable="lg"
    type="dark"
    class="bg-blue-primary"
  >
    <b-navbar-brand href="#" @click="goToDashboard">
      <img
        width="120px"
        src="./../assets/images/Logo_EmPower-2020-white.png"
        fluid
        alt="logo"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto font-weight-light">
        <b-nav-item
          v-for="(menuItem, index) in mainMenu"
          :key="index"
          class="px-md-2"
          active
          @click="redirectToPage(menuItem.routeName, menuItem.isExternalURL)"
          >{{ $t(`mainMenu.${menuItem.name}`) }}</b-nav-item
        >
        <b-nav-item v-if="isLoggedIn" class="px-md-2" active @click="logout">
          {{ $t("mainMenu.logout") }}
        </b-nav-item>
        <div class="px-md-2">
          <base-locale-switcher />
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import BaseLocaleSwitcher from "./Base/BaseLocaleSwitcher";

export default {
  components: {
    BaseLocaleSwitcher
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      publicMenu: [
        {
          name: "about",
          routeName: "https://www.emotiva.it/",
          isExternalURL: true
        },
        {
          name: "solutions",
          routeName: "https://www.emotiva.it/media-analytics/",
          isExternalURL: true
        },
        {
          name: "resources",
          routeName: "https://www.emotiva.it/riferimenti-scientifici-2/",
          isExternalURL: true
        },
        {
          name: "support",
          routeName: "Support",
          isExternalURL: false
        }
      ],
      privateMenu: [
        {
          name: "myAccount",
          routeName: "Account",
          isExternalURL: false
        }
      ]
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", null, { root: true });
    },
    goToDashboard() {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Login") {
        const routeName = this.$store.getters["auth/isLoggedIn"]
          ? "Dashboard"
          : "Login";
        this.$router.push({ name: routeName });
      }
    },
    redirectToPage(route, isExternalURL) {
      if (isExternalURL) window.location = route;
      else this.$router.push({ name: route });
    }
  },
  computed: {
    mainMenu() {
      const menu = this.isLoggedIn
        ? this.publicMenu.concat(this.privateMenu)
        : this.publicMenu;
      return menu;
    },
    getActiveLang() {
      return this.$i18n.locale === "it" ? "en" : "it";
    }
  }
};
</script>

<style scoped>
#nav {
  text-align: center;
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav-collapse a {
  font-size: 14px;
}
</style>
