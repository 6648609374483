<template>
  <div>
    <b-row class="min-vh-100 no-gutters flex-nowrap">
      <b-col v-if="isLoggedIn" class="col-auto side__menu-container">
        <main-side-menu />
      </b-col>
      <b-col class="main-content">
        <main-menu :isLoggedIn="isLoggedIn" />
        <router-view :key="$route.name" />
      </b-col>
    </b-row>
    <footer class="bg-blue-primary" style="height:100px"></footer>

    <base-success-dialog />
    <base-error-dialog />
    <base-confirm-dialog />
  </div>
</template>

<script>
import MainMenu from "./../components/MainMenu";
import MainSideMenu from "./../components/MainSideMenu";
export default {
  metaInfo() {
    return {
      title: this.getMetaTitle || "Emotiva | Dashboard",
      titleTemplate: "%s"
      // link: [{ rel: "favicon", href: "favicon.ico" }]
    };
  },
  components: {
    MainMenu,
    MainSideMenu
  },
  data() {
    return {};
  },
  computed: {
    getMetaTitle() {
      return this.$route.meta.info ? this.$route.meta.info.title : null;
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    }
  }
};
</script>

<style scoped>
.side__menu-container {
  position: sticky;
  z-index: 3;
  background-color: #3f3f3f;
  width: 60px;
  height: 100vh;
  top: 0;
}
.main-content {
  width: calc(100% - 60px);
}
</style>
